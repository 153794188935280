<template>
  <div class="readme-article">
    <h1 id="申请审批">
      三、申请审批
    </h1>
    <h2 id="赠课-学币审核">1.赠课/学币审核</h2>
    <p>查看赠课/学币审核列表，管理员可根据实际情况，审核是否通过。</p>
    <p>
      <img src="@/assets/img/finance/3-1.png">
    </p>
    <h2 id="退款审核">2.退款审核</h2>
    <p>查看退款审核审核列表，管理员可根据实际情况，审核是否通过。</p>
    <p>
      <img src="@/assets/img/finance/3-2.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Finance3-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>